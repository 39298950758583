import React, { useEffect, useState } from 'react'
import { API_MANAGE_GAME_LANGUAGE } from '../../../../utils/constantsAdmin'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { returnProcessUrl } from '../../../../utils/general_functions_forms'
import { ListLevelsLanguage } from '../LevelsLanguage/ListLevelsLanguage'
import { ItemGameLanguage } from './ItemGameLanguage'
import '../../../../styles/ListGameLanguage.scss'
import { useTranslation } from 'react-i18next'
import { ManageStorageGameLanguage } from '../../../../utils/manage_local_storage'

export const ListGameLanguage = () => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	// LOCAL STORAGE
	const { getGameLanguage, setGameLanguage } = ManageStorageGameLanguage()

	const [listGameLanguage, setListGameLanguage] = useState({})
	const [selectedGameLanguage, setSelectedGameLanguage] = useState(null)
	const [render, setRender] = useState(false)

	useEffect(() => {
		setListGameLanguage({})
		fetchOptionsGameLanguage(
			axiosSupreme,
			API_MANAGE_GAME_LANGUAGE,
			setListGameLanguage
		)
		if (getGameLanguage()) {
			let idGameLanguage = JSON.parse(getGameLanguage())
			setSelectedGameLanguage(idGameLanguage.id_game_language)
		}
	}, [render])

	const selectLevelLanguage = () => {
		setGameLanguage(JSON.stringify(listGameLanguage[selectedGameLanguage]))
		window.location.reload()
	}

	return (
		<div className='containerListGameLanguages'>
			<div className='containerListGameLanguagesList'>
				{Object.keys(listGameLanguage).map((key) => (
					<ItemGameLanguage
						key={key}
						type={'existing'}
						objectGameLanguage={listGameLanguage[key]}
						selectedGameLanguage={selectedGameLanguage}
						setSelectedGameLanguage={setSelectedGameLanguage}
						setRender={setRender}
					/>
				))}
				<ItemGameLanguage type={'new'} setRender={setRender} />
			</div>
			{selectedGameLanguage && (
				<button
					className='editorBtnActionAdd'
					onClick={selectLevelLanguage}>
					Seleccionar
				</button>
			)}
		</div>
	)
}

const fetchOptionsGameLanguage = async (axiosSupreme, url, setList) => {
	url = returnProcessUrl(url)
	let result_data = await axiosSupreme('get', url, undefined)
	result_data.results.map((data_item) => {
		setList((prevData) => ({
			...prevData,
			[data_item.id_game_language]: {
				id_game_language: data_item.id_game_language,
				base_language: data_item.base_language,
				language_to_learn: data_item.language_to_learn,
			},
		}))
	})
	if (result_data.next) {
		fetchOptionsGameLanguage(axiosSupreme, result_data.next, setList)
	}
}
