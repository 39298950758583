import '../../../styles/AdminDesign.scss'
import { Alert } from '../../../components/editor/Alert'
import Modal from '../../../components/editor/Modal'
import { ListLevelsLanguage } from '../../../components/editor/design/LevelsLanguage/ListLevelsLanguage'
import { useAuthProvider } from '../../../context/AuthProvider/AuthProvider'
import { ObjectLevelLanguageProvider } from '../../../components/editor/design/LevelsLanguage/ObjectLevelLanguage'
import { Toaster } from 'react-hot-toast'
import ModalDelete from '../../../components/editor/ModalDelete'
import { ErrorPerms } from '../../../components/editor/ErrorPerms'
import useAuthNavigator from '../../../context/AuthProvider/useAuthNavigator'
import { useContext, useEffect, useState } from 'react'
import TitleAdministratorAndMenu from '../../../components/editor/TitleAdministratorAndMenu'
import { BackButton } from '../../../components/editor/BackButton'
import { useTranslation } from 'react-i18next'
import { ButtonAdminLevelLanguage } from '../../../components/editor/design/ButtonAdminLevelLanguage'
import { ManageStorageGameLanguage } from '../../../utils/manage_local_storage'
import { ListGameLanguage } from '../../../components/editor/design/GameLanguages/ListGameLanguage'
import ModalContext from '../../../context/editor/ModalContext'

const Editor = () => {
	return useAuthNavigator(<PrivatePage />)
}

const PrivatePage = () => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// LOCAL STORAGE
	const { getGameLanguage } = ManageStorageGameLanguage()
	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	const [renderAdmin, setRenderAdmin] = useState(false)

	// RESET SCROLL
	useEffect(() => {
		window.scroll(0, 0)
		if (getGameLanguage()) {
			setRenderAdmin(true)
		} else {
			selectGameLanguage()
		}
	}, [])

	const selectGameLanguage = () => {
		setTitleModal('Lenguajes de Juego')
		setContentModal(<ListGameLanguage />)
		openModal()
	}

	return (
		<div className='adminD'>
			<ObjectLevelLanguageProvider>
				<Alert />
				<Modal />
				<ModalDelete />
				<Toaster />
				<TitleAdministratorAndMenu
					name={t('pages.editor.components.title_admin_menu.content')}
				/>
				<BackButton url={'/'} />
				<ButtonAdminLevelLanguage />

				{renderAdmin &&
					(gameUser.permissions?.hasOwnProperty(
						'view_designer_administration'
					) &&
					gameUser.permissions?.hasOwnProperty(
						'view_level_language'
					) ? (
						<ListLevelsLanguage />
					) : (
						<ErrorPerms />
					))}
			</ObjectLevelLanguageProvider>
		</div>
	)
}

export default Editor
