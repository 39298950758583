import { BASE_API, ACCOUNT_BACKEND } from '../urls'

// CONSTANTS
export const API_GET_CONSTANTS = BASE_API + '/general/get_constants/'
export const UPDATE_DATA_USER = ACCOUNT_BACKEND + '/admin_users/users/'

//LEVEL LANGUAGE
export const API_MANAGE_LIST_LEVELS_LANGUAGES =
	BASE_API + '/world_admin/level_language/list_by_game_language/' // + GAME_LANGUAGE

export const API_MANAGE_LEVELS_LANGUAGES =
	BASE_API + '/world_admin/level_language/'

//WORLDS
export const API_MANAGE_WORLDS = BASE_API + '/world_admin/world/'
export const GET_WORLDS_BY_LEVEL =
	BASE_API + '/world_admin/world/list_by_level/'
export const GET_WORLDS_BY_GAME_LANGUAGE =
	BASE_API + '/world_admin/world/list_by_game_language/'

//SUBWORLDS
export const API_MANAGE_SUBWORLDS = BASE_API + '/world_admin/sub_world/'
export const GET_SUB_WORLD_BY_WORLD =
	BASE_API + '/world_admin/sub_world/list_by_world/'
export const GET_SUB_WORLD_BY_GAME_LANGUAGE =
	BASE_API + '/world_admin/sub_world/list_by_game_language/'
//SCENEWORLDS
export const API_MANAGE_SCENE_WORLDS = BASE_API + '/world_admin/world_scene/'
export const GET_SCENE_WORLD_BY_WORLD =
	BASE_API + '/world_admin/world_scene/list_by_world/'

//STARS
export const API_MANAGE_STARS = BASE_API + '/world_admin/star/'

//ANIMATIONS
export const API_MANAGE_ANIMATIONS = BASE_API + '/world_admin/animations/'

// DECORATIONS
export const API_MANAGE_DECORATIONS =
	BASE_API + '/world_admin/world_scene_decoration/'
export const GET_DECORATIONS_BY_SCENE =
	BASE_API + '/world_admin/world_scene_decoration/list_by_scene/'

//  WORLD DOORS
export const API_MANAGE_DOORS = BASE_API + '/world_admin/world_door/'
export const GET_DOORS_BY_SCENE =
	BASE_API + '/world_admin/world_door/list_by_scene/'

// DESTINATION WORLD DOORS
export const API_MANAGE_PATH_DOORS = BASE_API + '/world_admin/world_door_path/'

//SCENE SUBWORLDS
export const API_MANAGE_SCENE_SUB_WORLDS =
	BASE_API + '/world_admin/sub_world_scene/'
export const GET_SCENE_SUBWORLD_BY_SUBWORLD =
	BASE_API + '/world_admin/sub_world_scene/list_by_sub_world/'

// DECORATIONS SUBWORLD
export const API_MANAGE_DECORATIONS_SUB_WORLD =
	BASE_API + '/world_admin/sub_world_scene_decoration/'
export const GET_DECORATIONS_BY_SCENE_SUB_WORLD =
	BASE_API + '/world_admin/sub_world_scene_decoration/list_by_scene/'

//  SUBWORLD DOORS
export const API_MANAGE_SUBWORLD_DOORS =
	BASE_API + '/world_admin/sub_world_door/'
export const GET_SUBWORLD_DOORS_BY_SCENE =
	BASE_API + '/world_admin/sub_world_door/list_by_scene/'

// DESTINATION SUBWORLD DOORS
export const API_MANAGE_PATH_SUBWORLD_DOORS =
	BASE_API + '/world_admin/sub_world_door_path/'

//  NPC DATA
export const API_MANAGE_NPC = BASE_API + '/npc_admin/npc/'

export const API_MANAGE_NPC_OUTFITS = BASE_API + '/npc_admin/npc_outfit/'

export const API_MANAGE_NPC_POSES = BASE_API + '/npc_admin/npc_pose/'

export const API_MANAGE_NPC_STATES = BASE_API + '/npc_admin/npc_state/'

// SOUNDS
export const API_MANAGE_SOUNDS = BASE_API + '/world_admin/sound/'

//NPC COMPOSE
export const API_MANAGE_NPC_COMPOSE = BASE_API + '/npc_admin/npc_compose/'
export const GET_NPC_COMPOSE_BY_NPC =
	BASE_API + '/npc_admin/npc_compose/list_by_npc/'

// NPC SUB WORLD NPC APPEARANCE
export const API_SUB_WORLD_SCENE_NPC_APPEARANCE =
	BASE_API + '/npc_admin/sub_world_scene_npc_appearance/'

// NPC COMPOSE SUB WORLD SCENE NPC APPEARANCE
export const API_NPC_SUB_WORLD_SCENE_NPC_APPEARANCE =
	BASE_API + '/npc_admin/npc_compose_sub_world_scene_npc_appearance/'
export const GET_NPC_SUB_WORLD_SCENE_NPC_APPEARANCE_BY_SCENE =
	BASE_API +
	'/npc_admin/npc_compose_sub_world_scene_npc_appearance/list_by_scene_sub_world/'
export const GET_MISSION_BY_SCENE_SUB_WORLD =
	BASE_API + '/mission_admin/mission/list_by_scene_sub_world/'
export const GET_CHECK_NPC_SUB_WORLD_SCENE_NPC_APPEARANCE_BY_SCENE =
	BASE_API +
	'/npc_admin/npc_compose_sub_world_scene_npc_appearance/check_by_compose_and_scene/'
export const GET_COORDINATE_NPC_SUB_WORLD_SCENE_NPC_APPEARANCE_BY_SCENE =
	BASE_API +
	'/npc_admin/npc_compose_sub_world_scene_npc_appearance/return_unified_coordinate/'

//STORE
export const API_MANAGE_STORE_CATEGORY =
	BASE_API + '/store_admin/item_category/'
export const API_MANAGE_STORE_ITEMS = BASE_API + '/store_admin/item/'

//DIALOGUES
export const API_MANAGE_DIALOGUES = BASE_API + '/npc_admin/dialogue/'
export const API_MANAGE_DIALOGUES_BY_NPC_COMPOSE =
	BASE_API + '/npc_admin/dialogue/list_by_npc_compose_scene/'

// MISSIONS
export const API_MANAGE_MISSIONS = BASE_API + '/mission_admin/mission/'
export const API_MANAGE_MISSIONS_BY_NPC_APPEARANCE =
	BASE_API + '/mission_admin/mission/list_by_npc_appearance/'
export const API_RESET_MISSIONS_PROFILE =
	BASE_API + '/mission_admin/mission_profile/reset_mission_profile/'
export const API_PASS_MISSIONS_PROFILE =
	BASE_API + '/mission_admin/mission_profile/pass_mission_profile/'

// MISSION EXCHANGE
export const API_EXCHANGE_MISSION =
	BASE_API + '/mission_admin/mission/process_exchange_missions/'

//CHALLENGES
export const API_MANAGE_CHALLENGES = BASE_API + '/mission_admin/challenge/'
// export const API_MANAGE_CHALLENGES_BY_NPC_COMPOSE = BASE_API + '/npc/dialogue/list_by_npc_compose_scene/';

//GAMES
export const API_MANAGE_GAMES = BASE_API + '/mission_admin/game/'
// export const API_MANAGE_CHALLENGES_BY_NPC_COMPOSE = BASE_API + '/npc/dialogue/list_by_npc_compose_scene/';

//STORYTELLERS BY SCENE
export const API_MANAGE_STORY_TELLER =
	BASE_API + '/storyteller_admin/storyteller/storyteller/'
export const GET_STORY_TELLER_BY_SCENE =
	BASE_API + '/storyteller_admin/storyteller/storyteller/list_by_scene_world/'

//STORYTELLERS BY SCENE SUBWORLD
export const API_MANAGE_STORY_TELLER_SUB_WORLD =
	BASE_API + '/storyteller_admin/storyteller/storyteller/'
export const GET_STORY_TELLER_BY_SCENE_SUB_WORLD =
	BASE_API +
	'/storyteller_admin/storyteller/storyteller/list_by_scene_sub_world/'

//STORYTELLER CONTENT

export const API_MANAGE_STORY_TELLER_CONTENT =
	BASE_API + '/storyteller_admin/storyteller/storyteller_content/'
export const GET_STORY_TELLER_CONTENT_BY_STORY_TELLER =
	BASE_API +
	'/storyteller_admin/storyteller/storyteller_content/list_by_storyteller/'
// CONTENT MISSION
export const API_CONTENT_MISSION = BASE_API + '/mission_admin/mission_content/'
export const API_GET_CONTENT_MISSION_BY_MISSION =
	BASE_API + '/mission_admin/mission_content_add_ons/list_by_mission/'

// CONTENT MISSION ADD ONS
export const API_CONTENT_MISSION_ADD_ONS =
	BASE_API + '/mission_admin/mission_content_add_ons/'

//USERS ADMIN
export const API_MANAGE_USERS_ADMIN = BASE_API + '/users/users/'
//GROUPS ADMIN
export const API_MANAGE_GROUPS_ADMIN = BASE_API + '/users/groups/'

// GROUPS PERMISSIONS
export const API_MANAGE_PERMISSIONS_BY_GROUP =
	BASE_API + '/users/permissions/list_by_group/'
export const API_MANAGE_PERMISSIONS_EXCEPT_BY_GROUP =
	BASE_API + '/users/permissions/list_except_by_group/'

export const API_MANAGE_ADD_PERMISSION =
	BASE_API + '/users/groups/add_permission/'
export const API_MANAGE_DELETE_PERMISSION =
	BASE_API + '/users/groups/delete_permission/'

//USERS GROUPS

export const API_MANAGE_GROUP_BY_USER = BASE_API + '/users/groups/list_by_user/'
export const API_MANAGE_GROUP_EXCEPT_BY_USER =
	BASE_API + '/users/groups/list_except_by_user/'

export const API_MANAGE_ADD_GROUP = BASE_API + '/users/users/add_group/'
export const API_MANAGE_DELETE_GROUP = BASE_API + '/users/users/delete_group/'

export const API_RESET_STORYTELLER_PROFILE =
	BASE_API +
	'/profile_admin/profile/storyteller_profile/reset_storyteller_profile/'

export const API_CHANGE_NPC_SCENE =
	BASE_API +
	'/npc_admin/npc_compose_sub_world_scene_npc_appearance/process_change_npc/'

// GAME LANGUAGE
export const API_MANAGE_GAME_LANGUAGE = BASE_API + '/world_admin/game_language/'
