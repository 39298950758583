export const removeAllItems = () => {
	ManageStorageLevelLanguage().removeLevelLanguage()
	ManageStorageWorld().removeWorld()
	ManageStorageWorldScene().removeWorldScene()
	ManageStorageSubworld().removeSubworld()
	ManageStorageSceneSubworld().removeSceneSubworld()
}

export function ManageStorageGameLanguage() {
	const setGameLanguage = (value) => {
		localStorage.setItem('ITEM_GAME_LANGUAGE', value)
		ManageStorageLevelLanguage().removeLevelLanguage()
	}

	const getGameLanguage = () => {
		return localStorage.getItem('ITEM_GAME_LANGUAGE')
	}

	const removeGameLanguage = () => {
		localStorage.removeItem('ITEM_GAME_LANGUAGE')
	}

	return {
		setGameLanguage,
		getGameLanguage,
		removeGameLanguage,
	}
}

export function ManageStorageLevelLanguage() {
	const setLevelLanguage = (value) => {
		localStorage.setItem('ITEM_LEVEL_LANGUAGE', value)
		ManageStorageWorld().removeWorld()
	}

	const getLevelLanguage = () => {
		return localStorage.getItem('ITEM_LEVEL_LANGUAGE')
	}

	const removeLevelLanguage = () => {
		localStorage.removeItem('ITEM_LEVEL_LANGUAGE')
	}

	return {
		setLevelLanguage,
		getLevelLanguage,
		removeLevelLanguage,
	}
}

export function ManageStorageWorld() {
	const setWorld = (value) => {
		localStorage.setItem('ITEM_WORLD', value)
		ManageStorageWorldScene().removeWorldScene()
		ManageStorageSubworld().removeSubworld()
	}

	const getWorld = () => {
		return localStorage.getItem('ITEM_WORLD')
	}

	const removeWorld = () => {
		localStorage.removeItem('ITEM_WORLD')
		ManageStorageWorldScene().removeWorldScene()
		ManageStorageSubworld().removeSubworld()
	}

	return {
		setWorld,
		getWorld,
		removeWorld,
	}
}

export function ManageStorageWorldScene() {
	const setWorldScene = (value) => {
		localStorage.setItem('ITEM_WORLD_SCENE', value)
		ManageStorageSubworld().removeSubworld()
	}

	const getWorldScene = () => {
		return localStorage.getItem('ITEM_WORLD_SCENE')
	}

	const removeWorldScene = () => {
		localStorage.removeItem('ITEM_WORLD_SCENE')
	}

	return {
		setWorldScene,
		getWorldScene,
		removeWorldScene,
	}
}

export function ManageStorageSubworld() {
	const setSubworld = (value) => {
		localStorage.setItem('ITEM_SUBWORLD', value)
		ManageStorageWorldScene().removeWorldScene()
		ManageStorageSceneSubworld().removeSceneSubworld()
	}

	const getSubworld = () => {
		return localStorage.getItem('ITEM_SUBWORLD')
	}

	const removeSubworld = () => {
		localStorage.removeItem('ITEM_SUBWORLD')
	}

	return {
		setSubworld,
		getSubworld,
		removeSubworld,
	}
}

export function ManageStorageSceneSubworld() {
	const setSceneSubworld = (value) => {
		localStorage.setItem('ITEM_SCENE_SUBWORLD', value)
	}

	const getSceneSubworld = () => {
		return localStorage.getItem('ITEM_SCENE_SUBWORLD')
	}

	const removeSceneSubworld = () => {
		localStorage.removeItem('ITEM_SCENE_SUBWORLD')
	}

	return {
		setSceneSubworld,
		getSceneSubworld,
		removeSceneSubworld,
	}
}
