import React, { useState } from 'react'
import '../../styles/Offcanvas.scss'
import { BiMenu } from 'react-icons/bi'
import { FaRegUserCircle } from 'react-icons/fa'
import { IoCloseCircle } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { useAuthProvider } from '../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export default function TitleAdministratorAndMenu({ name }) {
	const { t } = useTranslation()
	return (
		<div className='adminD__title'>
			<h1>
				{t('pages.editor.components.title_admin_menu.admin_of')} {name}
				<Offcanvas />
			</h1>
		</div>
	)
}

function Offcanvas() {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, logout } = useAuthProvider()

	const [isOpen, setIsOpen] = useState(false)

	const toggleOffcanvas = () => {
		setIsOpen(!isOpen)
	}
	const closeOffcanvas = () => {
		setIsOpen(false)
	}

	// BACK TO GAME
	const navigate = useNavigate()
	const backtoGame = () => {
		navigate('/')
	}

	const goToDesignerAdmin = () => {
		navigate('/editor')
	}
	const goToUsersAdmin = () => {
		navigate('/gameUser-admin')
	}

	return (
		<>
			<button className='menuButton' onClick={toggleOffcanvas}>
				<BiMenu />
			</button>
			<div className={`offcanvas ${isOpen ? 'open' : ''}`}>
				<div className='offcanvas__titulo'>
					<h1>{t('common.menu')}</h1>
					<button className='closeButton' onClick={closeOffcanvas}>
						<IoCloseCircle></IoCloseCircle>
					</button>
				</div>
				<div className='offcanvas__contenido'>
					<div className='divLabel'>
						<label>
							<p className='iconsUser'>
								<FaRegUserCircle />
							</p>
							<p className='nameUser'>
								{gameUser.name !== null &&
								gameUser.last_name !== null
									? gameUser.name + ' ' + gameUser.last_name
									: gameUser.username}
							</p>
						</label>
						<button
							className='menuBtnsFooter btnAdd'
							onClick={logout}>
							{t('common.exit')}
						</button>
					</div>
					<button className='menuBtns btnAdd' onClick={backtoGame}>
						{t('common.play')}
					</button>
					{gameUser.permissions?.hasOwnProperty(
						'view_designer_administration'
					) ? (
						<button
							className='menuBtns btnAdd'
							onClick={goToDesignerAdmin}>
							{t(
								'pages.editor.components.title_admin_menu.admin_designer'
							)}
						</button>
					) : null}
					{gameUser.permissions?.hasOwnProperty(
						'view_users_groups_administration'
					) ? (
						<button
							className='menuBtns btnAdd'
							onClick={goToUsersAdmin}>
							{t(
								'pages.editor.components.title_admin_menu.admin_user_and_group'
							)}
						</button>
					) : null}
				</div>
			</div>
			{isOpen && (
				<div
					className='offcanvas-offverlay'
					onClick={closeOffcanvas}></div>
			)}
		</>
	)
}
