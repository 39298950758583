import { createContext, useState } from 'react'

export const ObjectLevelLanguageContext = createContext()

export const ObjectLevelLanguageProvider = (props) => {
	const [gameLanguage, setGameLanguage] = useState(undefined)
	const [objectLevelLanguage, setObjectLevelLanguage] = useState(undefined)

	return (
		<ObjectLevelLanguageContext.Provider
			value={{ objectLevelLanguage, setObjectLevelLanguage }}>
			{props.children}
		</ObjectLevelLanguageContext.Provider>
	)
}
